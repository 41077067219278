import autoBind from "auto-bind";
import Options from "./options";
import Theme from "./theme";
import makeTheme from "src/themes/utils/makeTheme";
import get from "lodash/get";
import merge from "lodash/merge";
import memoizeClassMethods from "@ui/utils/memoizeClassMethods";

class TymberSite {
  constructor(
    siteObject,
    settingsObject = {},
    groupSettingsObject = {},
    themeObject = {}
  ) {
    this.siteObject = siteObject;
    this.settingsObject = settingsObject;
    this.groupSettingsObject = groupSettingsObject;
    this.themeObject = themeObject;
    autoBind(this);
  }

  getUiConfiguration() {
    return {
      sortFilters: {
        withBorder: get(
          this.themeObject.themeVariables,
          "components.sortFilters.withBorder"
        ),
      },
      categoryBar: {
        theme: get(this.themeObject.themeVariables, "components.categoryBar.theme", ""),
        scroll: get(this.themeObject.themeVariables, "components.categoryBar.scroll", {
          lg: false,
          md: false,
          sm: true,
        }),
        button: {
          shadow: get(
            this.themeObject.themeVariables,
            "components.categoryBar.button.shadow",
            false
          ),
          type: get(
            this.themeObject.themeVariables,
            "components.categoryBar.button.type",
            null
          ),
        },
      },
      productDetail: {
        promotionIndicator: {
          promotionText: get(
            this.themeObject.themeVariables,
            "components.productDetail.promotionIndicator.promotionText",
            null
          ),
        },
        flowerTypeIndicator: {
          before: get(
            this.themeObject.themeVariables,
            "components.productDetail.flowerTypeIndicator.before",
            false
          ),
          showIcon: get(
            this.themeObject.themeVariables,
            "components.productDetail.flowerTypeIndicator.showIcon",
            true
          ),
        },
        sizes: {
          position: get(
            this.themeObject.themeVariables,
            "components.productDetail.sizes.position",
            "bottom"
          ),
        },
        mainTagsContainer: {
          flexDirection: get(
            this.themeObject.themeVariables,
            "components.productDetail.mainTagsContainer.flexDirection",
            "row"
          ),
        },
        cartIcon: get(
          this.themeObject.themeVariables,
          "components.productDetail.cartIcon",
          "default"
        ),
        addToCartText: get(
          this.themeObject.themeVariables,
          "components.productDetail.addToCartText",
          "Add to cart"
        ),
      },
      header: {
        hideMenuButtonOnLargerViewport: get(
          this.themeObject.themeVariables,
          "components.header.hideMenuButtonOnLargerViewport",
          false
        ),
        cartIcon: get(
          this.themeObject.themeVariables,
          "components.header.cartIcon",
          "default"
        ),
        cartText: get(
          this.themeObject.themeVariables,
          "components.header.cartText",
          null
        ),
        hideIconInLargerViewport: get(
          this.themeObject.themeVariables,
          "components.header.hideIconInLargerViewport",
          false
        ),
        highlight: get(
          this.themeObject.themeVariables,
          "components.header.highlight",
          this.getBaseTheme().name !== "default"
        ),
        highlightIfCartHasProducts: get(
          this.themeObject.themeVariables,
          "components.header.highlightIfCartHasProducts",
          true
        ),
        logoPosition: get(
          this.themeObject.themeVariables,
          "components.header.logoPosition",
          "left"
        ),
        showShopMenu: get(
          this.themeObject.themeVariables,
          "components.header.showShopMenu",
          false
        ),
        options: get(this.themeObject.themeVariables, "components.header.options", []),
        logo: {
          position: get(
            this.themeObject.themeVariables,
            "components.header.logo.position",
            "left"
          ),
        },
        cart: this.getCartUiConfiguration(),
      },
      footer: {
        showWarning: get(
          this.themeObject.themeVariables,
          "components.footer.showWarning",
          false
        ),
      },
      search: this.getSearchUiConfiguration(),
      login: this.getLoginUiConfiguration(),
      icons: {
        user: get(this.themeObject.themeVariables, "icons.user", "user"),
        search: get(this.themeObject.themeVariables, "icons.search", "search"),
        shopping: get(this.themeObject.themeVariables, "icons.shopping", "shopping-cart"),
        delivery: get(this.themeObject.themeVariables, "icons.delivery", "delivery"),
        mapPin: get(this.themeObject.themeVariables, "icons.mapPin", "map-pin"),
        history: get(this.themeObject.themeVariables, "icons.history", "history"),
        home: get(this.themeObject.themeVariables, "icons.home", "home"),
        inStoreExperience: get(
          this.themeObject.themeVariables,
          "icons.inStoreExperience",
          "inStoreExperience"
        ),
      },
      showcasedProductsList: {
        viewAll: {
          position: get(
            this.themeObject.themeVariables,
            "components.showcasedProductsList.viewAll.position"
          ),
          text: get(
            this.themeObject.themeVariables,
            "components.showcasedProductsList.viewAll.text",
            ""
          ),
        },
        element: {
          maxWidth: get(
            this.themeObject.themeVariables,
            "components.showcasedProductsList.element.maxWidth",
            {lg: 320, md: 320, sm: 320}
          ),
        },
        productsPerLine: merge(
          {},
          {lg: 5, md: 4, sm: 2},
          get(
            this.themeObject.themeVariables,
            "components.showcasedProductsList.productsPerLine",
            {}
          )
        ),
        showCategoryCard: get(
          this.themeObject.themeVariables,
          "components.showcasedProductsList.showCategoryCard",
          false
        ),
        showHeader: get(
          this.themeObject.themeVariables,
          "components.showcasedProductsList.showHeader",
          this.getBaseTheme().name === "colourful"
        ),
      },
      productsList: {
        productsPerLine: merge(
          {},
          {lg: 5, md: 4, sm: 1},
          get(
            this.themeObject.themeVariables,
            "components.productsList.productsPerLine",
            {}
          )
        ),
      },
      brandPage: {
        hideDescription: get(
          this.themeObject.themeVariables,
          "components.brandPage.hideDescription",
          false
        ),
      },
      hideDeliveryBanner: get(
        this.themeObject.themeVariables,
        "components.hideDeliveryBanner",
        false
      ),
      filters: {
        inlineOnLargeScreen: get(
          this.themeObject.themeVariables,
          "components.filters.inlineFiltersOnLargeScreen",
          false
        ),
        collapseTagFilters: get(
          this.themeObject.themeVariables,
          "components.filters.collapseTagFilters",
          false
        ),
      },
      productCard: {
        brandNamePosition: get(
          this.themeObject.themeVariables,
          "components.productCard.brandNamePosition",
          "below-product-name"
        ),
        buyText: get(
          this.themeObject.themeVariables,
          "components.productCard.buyText",
          "Buy Now"
        ),
        zoomOnHover: get(
          this.themeObject.themeVariables,
          "components.productCard.zoomOnHover",
          false
        ),
        height: get(
          this.themeObject.themeVariables,
          "components.productCard.height",
          null
        ),
        imageHeight: get(
          this.themeObject.themeVariables,
          "components.productCard.imageHeight",
          null
        ),
        saleIndicator: {
          margin: get(
            this.themeObject.themeVariables,
            "components.productCard.saleIndicator.margin",
            "0px"
          ),
        },
        flowerTypeIndicator: {
          flowerTypePosition: get(
            this.themeObject.themeVariables,
            "components.productCard.flowerTypeIndicator.flowerTypePosition",
            "image"
          ),
          margin: get(
            this.themeObject.themeVariables,
            "components.productCard.flowerTypeIndicator.margin",
            undefined
          ),
          showIcon: get(
            this.themeObject.themeVariables,
            "components.productCard.flowerTypeIndicator.showIcon",
            true
          ),
        },
        actionsContainer: {
          padding: get(
            this.themeObject.themeVariables,
            "components.productCard.actionsContainer.padding",
            "0 0 8px 0"
          ),
        },
        alwaysDesktopMode: get(
          this.themeObject.themeVariables,
          "components.productCard.alwaysDesktopMode",
          false
        ),
        sizes: {
          sizesPosition: get(
            this.themeObject.themeVariables,
            "components.productCard.sizes.sizesPosition",
            "above-potency-tags"
          ),
        },
        cartIcon: get(
          this.themeObject.themeVariables,
          "components.productCard.cartIcon",
          "default"
        ),
        actionsMode:
          this.env.getFiltersModeOnLargeScreen() ||
          get(this.themeObject.themeVariables, "components.productCard.cartActions")
            ? "small"
            : "auto",
        mode: get(this.themeObject.themeVariables, "components.productCard.mode", "big"),
        isHorizontalInMobile:
          get(
            this.themeObject.themeVariables,
            "components.productCard.isHorizontalInMobile"
          ) === "true",
      },
      categorizedProductsBrowser: {
        subTitle: get(
          this.themeObject.themeVariables,
          "components.categorizedProductsBrowser.subTitle",
          "Find the perfect liftoff with products curated by our flight team for you."
        ),
      },
      addToCartButton: {
        position: get(
          this.themeObject.themeVariables,
          "components.addToCartButton.position",
          "left"
        ),
      },
      flowerTypeIndicator: {
        withIcon: get(
          this.themeObject.themeVariables,
          "components.flowerTypeIndicator.withIcon",
          "true"
        ),
        indicaColor: get(
          this.themeObject.themeVariables,
          "components.flowerTypeIndicator.indicaColor"
        ),
        sativaColor: get(
          this.themeObject.themeVariables,
          "components.flowerTypeIndicator.sativaColor"
        ),
        hybridColor: get(
          this.themeObject.themeVariables,
          "components.flowerTypeIndicator.hybridColor"
        ),
        contrastColor: get(
          this.themeObject.themeVariables,
          "components.flowerTypeIndicator.contrastColor"
        ),
        iconType: get(
          this.themeObject.themeVariables,
          "components.flowerTypeIndicator.iconType"
        ),
        displayAbbreviation: get(
          this.themeObject.themeVariables,
          "components.flowerTypeIndicator.displayAbbreviation"
        ),
      },
      saleIndicator: {
        hasFlowerTypeIndicator: get(
          this.themeObject.themeVariables,
          "components.saleIndicator.hasFlowerTypeIndicator"
        ),
      },
      brandNamePosition: get(
        this.themeObject.themeVariables,
        "components.productCard.brandNamePosition",
        "below-product-name"
      ),
    };
  }

  getSearchUiConfiguration() {
    const themeName = this.getBaseTheme().name;
    if (themeName === "default") return {};

    return {
      searchIcon: get(
        this.themeObject.themeVariables,
        "components.search.searchIcon",
        "default"
      ),
      highlight: get(
        this.themeObject.themeVariables,
        "components.search.highlight",
        themeName === "colourful"
      ),
      outline: get(this.themeObject.themeVariables, "components.search.outline", true),
    };
  }

  getCartUiConfiguration() {
    if (this.getBaseTheme().name === "default") return {highlight: false};

    return {highlight: true};
  }

  getLoginUiConfiguration() {
    if (this.getBaseTheme().name === "default") return {};

    return {
      outline: get(this.themeObject.themeVariables, "components.login.outline", false),
      iconOnly: get(this.themeObject.themeVariables, "components.login.iconOnly", false),
      icon: get(this.themeObject.themeVariables, "components.login.icon", "default"),
    };
  }

  getGroupName() {
    return this.siteObject.group_name;
  }

  getName() {
    return this.siteObject.name;
  }

  getId() {
    return this.siteObject.uuid;
  }

  getTitle() {
    return this.siteObject.html_title;
  }

  getDescription() {
    return this.siteObject.meta_description;
  }

  getFavicon() {
    return this.siteObject.favicon_url;
  }

  getLogoUrl() {
    return this.siteObject.logo_url;
  }

  getTheme() {
    const baseTheme = get(
      this.themeObject.baseTheme,
      "attributes.default_configuration_variables",
      null
    );
    const themeName = get(this.themeObject.baseTheme, "attributes.name", "default");
    return makeTheme(this.themeObject.themeVariables, baseTheme, themeName);
  }

  getHtmlHead() {
    return this.siteObject.html_head;
  }

  getBannerUrl() {
    return this.siteObject.banner_url;
  }

  getHomeLinkUrl() {
    return this.siteObject.home_link_url || undefined;
  }

  getSiteUrl() {
    return this.siteObject.url || undefined;
  }

  getBaseTheme() {
    return new Theme(this.themeObject.baseTheme);
  }

  serialize() {
    return this.siteObject;
  }

  getAllowedCountryPhoneNumbers() {
    return (
      this.getOptions().getAllowedCountryPhoneNumbers() ||
      this.env.getAllowedCountryPhoneNumbers()
    );
  }

  getOptions() {
    const deliveriesPath = this.env ? this.env.getDeliveriesPath() : "deliveries";
    const obj = {
      ...this.settingsObject,
      deliveries_path: deliveriesPath ? "/" + deliveriesPath : undefined,
      allow_change_user_address: get(this.groupSettingsObject, "allow_deliveries", false),
      getCategoryDefaultProductOrder: this.env
        ? this.env.getCategoryDefaultProductOrder
        : () => undefined,
    };

    const groupObj = Object.keys(this.groupSettingsObject).reduce((acc, key) => {
      return {
        ...acc,
        [`group_${key}`]: this.groupSettingsObject[key],
      };
    }, {});

    return new Options({
      ...obj,
      ...groupObj,
    });
  }

  getContentHost() {
    return this.env ? this.env.getContentHost() : "";
  }

  getPoweredByName() {
    return this.env?.getPoweredByName() || this.getOptions().poweredByText();
  }

  setEnv(env) {
    this.env = env;
    this.settingsObject = {
      ...this.settingsObject,
      force_delivery_type_selection: env.getForceDeliveryTypeSelection(),
      allow_documents: env.getAllowDocuments(),
      announcement_schedule_for_later: env.getAnnouncementScheduleForLater(),
      schedule_page_size: env.getSchedulePageSize(),
      enabled_filters: env.getEnabledFilters(),
      price_filter_visible: env.getPriceFilterVisible(),
      offsite_kiosk: env.getKioskId(),
      kiosk: env.getAppMode() === "kiosk",
      allow_tips: env.getAllowTips(),
      disabled_coupons:
        env.getDisableCoupons() === "true" || env.getDisableCoupons() === true,
      disabled_rewards:
        env.getDisableRewards() === "true" || env.getDisableRewards() === true,
      transaction_type_text: env.getTransactionTypeText(),
      show_recommended_products_in_cart: env.getShowRecommendedProductsInCart(),
      default_country: env.getDefaultCountry() || "US",
      allow_pickup_checkout_notes: env.getAllowPickupCheckoutNotes() !== "false",
      stronghold_onboarding: env.getStrongholdOnBoardingEnabled(),
      order_brands_alphabetically_in_filters:
        env.getOrderBrandsAlphabeticallyInFilters() === "true" ||
        env.getOrderBrandsAlphabeticallyInFilters() === true,
      hide_powered_by:
        env.getHidePoweredBy() === "true" || env.getHidePoweredBy() === true,
    };

    this.clearAllCaches();
  }

  clearAllCaches() {
    // dummy, gets overriden by memoizeClassMethods
  }
}

memoizeClassMethods(TymberSite, ["setEnv"]);

export default TymberSite;
