import React from "react";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import styled from "styled-components";
import {
  isMobile,
  renderNodeOrComponent,
  SimpleProductsList,
  ProductListHeader,
} from "@ui";
import useTheme from "@mock/hooks/useTheme.js";
import topPicksProductsListStyles from "@themes/default/components/ShowcaseRecommendedProductsList/styles";
import useUserTopPicks from "@mock/hooks/useUserTopPicks";
import useShowcaseProductsList from "@mock/hooks/useShowcaseProductsList";
import {RecommendationsIcon} from "@ui/components/IconsSVGs";

export default function ShowcaseRecommendedProductsList({
  ProductsListComponent,
  HeaderComponent,
  filters,
  overrideThemeName,
  overrideThemeProps,
}) {
  const {ProductCard, Title} = useThemeComponents();
  const theme = useTheme();
  const {products, meta, site, isUserLoggedIn, showRecommendations} =
    useUserTopPicks(filters);

  const {LoadingComponent} = useShowcaseProductsList({url: ""});

  const {productsPerLine} = site.getUiConfiguration().showcasedProductsList;

  const themeName = site.getBaseTheme().name;
  const styles = topPicksProductsListStyles(theme, site);

  const title = isUserLoggedIn ? "Recommended for you" : "Recommended for just you";
  const description = isUserLoggedIn
    ? "Products we think you’ll like based on your purchase history"
    : "Products we think you’ll love";

  const showDescription = !isMobile() ? description : "";

  if (!showRecommendations) {
    return null;
  }

  const productsPerSlide = styles.element.elementsPerRow || {
    lg: 6,
    md: 6,
    sm: 6,
  };

  const header = HeaderComponent ? (
    renderNodeOrComponent(HeaderComponent, {
      TitleComponent: Title,
      title: title,
    })
  ) : (
    <ProductListHeader
      IconComponent={<RecommendationsIcon />}
      title={title}
      description={showDescription}
    />
  );

  let productsList;
  if (!ProductsListComponent) {
    productsList = (
      <SimpleProductsList
        styles={styles}
        maxProducts={productsPerSlide}
        products={products}
        skeleton={meta.loading}
        ProductCardComponent={ProductCard}
        HeaderComponent={header}
      />
    );
  } else {
    const additionalProps = themeName === overrideThemeName ? overrideThemeProps : {};
    productsList = renderNodeOrComponent(ProductsListComponent, {
      styles: styles,
      products: products,
      maxProductsPerSlide: productsPerLine,
      skeleton: meta.loading,
      loading: meta.loading,
      ProductCardComponent: ProductCard,
      onLastSlide: () => {},
      LoadingComponent: LoadingComponent,
      HeaderComponent: header,
      ...additionalProps,
    });
  }

  return <OuterContainer styles={styles}>{productsList}</OuterContainer>;
}

const OuterContainer = styled.div`
  background: ${({styles}) => styles.container.color};
  margin-bottom: 20px;
  padding-top: 20px;
`;
