import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import PropTypes from "prop-types";
import {DiagonalArrowRightUpOutline} from "@styled-icons/evaicons-outline/DiagonalArrowRightUpOutline";
import {isMobile} from "@ui/utils/isMobile";

function CardHeader({
  showHeader,
  isFirst,
  headerProps,
  styles,
  viewAllText,
  LinkComponent,
}) {
  return (
    <>
      {showHeader && isFirst && headerProps.url && !isMobile() && (
        <LinkComponent to={headerProps.url}>
          <Container styles={styles.header.root}>
            <Title styles={styles.header.title}>{headerProps.category}</Title>
            <ViewAllContainer>
              <ViewAllText styles={styles.header.viewAllText}>{viewAllText}</ViewAllText>
              <ArrowIcon styles={styles.header.arrow} />
            </ViewAllContainer>
          </Container>
        </LinkComponent>
      )}
    </>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: ${({styles}) => styles.backgroundColor};
  position: absolute;
  top: -50px;
  left: 0;
  height: 75px;
  border-radius: ${({styles}) => styles.borderRadius};
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  font-family: ${({styles}) => styles.fontFamily};

  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};
  text-transform: capitalize;
  padding-bottom: 25px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ArrowIcon = styled(DiagonalArrowRightUpOutline)`
  color: ${({styles}) => styles.color};

  padding-bottom: 25px;
  padding-right: 20px;

  ${media.up("lg")} {
    width: 30px;
  }

  ${media.down("md")} {
    width: 25px;
  }

  ${media.down("sm")} {
    width: 25px;
  }
`;
const ViewAllContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const ViewAllText = styled.span`
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};
  padding-bottom: 20px;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

CardHeader.propTypes = {
  showHeader: PropTypes.bool,
  isFirst: PropTypes.bool,
  headerProps: PropTypes.object,
  styles: PropTypes.object,
  LinkComponent: PropTypes.elementType,
};

export default CardHeader;
