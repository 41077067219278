import styled from "styled-components";
import media from "@ui/utils/media";
import {Button} from "@ui";
import {BackIcon} from "@ui/components/Icons";
import React from "react";
import useTheme from "@mock/hooks/useTheme";
import {backButtonStyles} from "@themes/default/components/BackButton/styles";

export default function BackButton({label = "Back", onClick}) {
  const theme = useTheme();

  return (
    <ButtonContainer>
      <Button
        styles={backButtonStyles(theme)}
        label={label}
        variant="secondary"
        LeftIconComponent={BackIcon}
        onClick={onClick}
      />
    </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  padding: 0 16px 16px 32px;

  ${media.down("md")} {
    padding: 16px 16px;
  }
`;
