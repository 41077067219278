import React from "react";
import styled from "styled-components";
import {renderNodeOrComponent} from "@ui";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import useBasePage from "@mock/hooks/useBasePage";
import PromotionalBanner from "@themes/default/components/PromotionalBanner";
import {ProductDetailModal} from "@themes/default/components/ProductDetail";
import dynamic from "next/dynamic";
import media from "@ui/utils/media";

const AdBanner = dynamic(() => import("@themes/default/components/AdBanner"));

export default function BasePage({children, currentPage, ...props}) {
  const {
    kioskMode,
    showAnnouncementMessage,
    showBanner,
    showAdBanner,
    showCategoryBar,
    categoryBarPosition,
    addons,
  } = useBasePage(currentPage);
  const {DeliveryBanner, CategoryBar, Header, ProductDetail, AnnouncementMessage} =
    useThemeComponents();

  return (
    <div>
      <Header />
      {!kioskMode && <DeliveryBanner />}
      {showCategoryBar && categoryBarPosition === "top" && <CategoryBar />}
      {showAnnouncementMessage && <AnnouncementMessage />}
      {showBanner && <PromotionalBanner />}
      {showCategoryBar && categoryBarPosition === "bottom" && <CategoryBar />}
      {showAdBanner && <AdBanner />}
      <Container
        disableMaxWidth={props.disableMaxWidth}
        categoryBarPosition={categoryBarPosition}
      >
        {children}
      </Container>
      <ProductDetailModal ProductDetailComponent={ProductDetail} {...props} />
      {addons.map(addon => renderNodeOrComponent(addon.component, addon.props))}
    </div>
  );
}

const Container = styled.div`
  max-width: ${({disableMaxWidth}) => (disableMaxWidth ? "none" : "1440px")};
  margin: 0 auto;

  padding-top: 20px;
  ${media.down("sm")} {
    padding-top: ${({categoryBarPosition}) =>
      categoryBarPosition === "bottom" ? "20px" : "0"};
    .ad-banner__container {
      margin-bottom: ${({categoryBarPosition}) =>
        categoryBarPosition === "bottom" ? "0" : "20px"};
    }
  }
`;
