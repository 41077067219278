import React from "react";
import styled from "styled-components";
import Header from "src/core/common/components/collections/menu/simple";
import AnnouncementMessage from "@menu/components/AnnouncementMessage";
import DeliveryBanner from "src/core/deliveries/components/DeliveryBanner";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import Breadcrumbs from "src/themes/flight/components/Breadcrumbs";
import useBasePage from "src/core/common/hooks/useBasePage";

export default function FlightBasePage({children, ...props}) {
  const {showAnnouncementMessage} = useBasePage();
  const toggles = useFeatureToggles();
  return (
    <>
      {!toggles.isKiosk() && <DeliveryBanner />}
      <Header isSearchEnabled={true} />
      {showAnnouncementMessage && <AnnouncementMessage />}
      <Content>
        <Breadcrumbs />
      </Content>
      <Content disableMaxWidth={props.disableMaxWidth}>{children}</Content>
    </>
  );
}

const Content = styled.div`
  margin: 0 auto;
  max-width: ${({disableMaxWidth}) => (disableMaxWidth ? "none" : "1440px")};
`;
