import {createGlobalStyle} from "styled-components";

const GlobalStyles = createGlobalStyle`
    #nprogress .bar {
        background: ${({theme}) => theme.v2.color.primary} !important;
    }

    body, span, button, div {
        font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
    }

    h2 {
        font-family: ${({theme}) => theme.v2.typography.title2.family};
        line-height: ${({theme}) => theme.v2.typography.title2.lineHeight};
        letter-spacing: ${({theme}) => theme.v2.typography.title2.letterSpacing};
    }

    h3 {
        font-family: ${({theme}) => theme.v2.typography.title3.family};
    }

    h1 {
        font-family: ${({theme}) => theme.v2.typography.title1.family};
    }

    h4 {
        font-family: ${({theme}) => theme.v2.typography.title4.family};
    }

    body {
        margin: 0 !important;
    }

    ${process.env.NEXT_PUBLIC_ENV === "test" ? `nextjs-portal { display: none; }` : ""}
`;
export default GlobalStyles;
