import React, {useState} from "react";
import styled, {useTheme} from "styled-components";
import dynamic from "next/dynamic";
import {FiltersAndDeliveryBanner} from "@ui";
import {logModuleLoadError} from "src/core/common/utils";
import FiltersModalService from "@menu/services/filtersModalService";
import useSite from "src/core/sites/hooks/useSite";
import useAppliedFilters from "src/core/common/hooks/useAppliedFilters";
import useDeliveryBanner from "src/core/common/hooks/useDeliveryBanner";
import DeliveryModal from "src/themes/legacy/components/DeliveryModal";
import getDeliveryTypeModeText from "@ui/utils/deliveryTypeModeText";
import styles from "src/themes/medleaf/components/DeliveryBanner/styles";

const CarIcon = dynamic(() =>
  import("../../../../core/common/assets/car.svg").catch(logModuleLoadError("CarIcon"))
);

const Car = ({color}) => {
  return (
    <IconImage color={color}>
      <CarIcon />
    </IconImage>
  );
};

export default function MedLeafDeliveryBanner() {
  const theme = useTheme();
  const site = useSite();
  const _styles = styles(theme, site);

  const [filters] = useAppliedFilters();
  const {
    deliveryModePickerProps,
    deliveryTypePickerProps,
    selectedDeliveryType,
    ...deliveryBannerProps
  } = useDeliveryBanner();

  const deliveryTypeModeText = getDeliveryTypeModeText(
    selectedDeliveryType?.code,
    deliveryModePickerProps.selectedMode
  );

  const [isShown, setIsShown] = useState(false);

  const handleClick = () => {
    setIsShown(true);
  };

  const onSuccess = () => {
    setIsShown(false);
  };

  const DeliveryBannerTextComponent = deliveryBannerProps?.deliveryLocation &&
    deliveryBannerProps?.deliveryLocation !== "none" && (
      <TextContainer>
        <Car color={_styles?.root?.color} />
        {deliveryTypeModeText} {deliveryBannerProps?.deliveryLocation}
      </TextContainer>
    );

  return (
    <Container>
      <FiltersAndDeliveryBanner
        DeliveryBannerTextComponent={DeliveryBannerTextComponent}
        onClickDeliveryBanner={handleClick}
        filtersCount={filters.getCounters().total}
        onChangeFilters={() => {
          FiltersModalService.show();
        }}
        styles={_styles}
      />
      <DeliveryModal
        isOpen={isShown}
        onSuccess={onSuccess}
        closeModal={() => setIsShown(false)}
      />
    </Container>
  );
}

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const IconImage = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;

  path {
    stroke: ${({color}) => color};
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: sofia-pro, sans-serif;
`;
