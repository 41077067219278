import React from "react";
import styled from "styled-components";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import useTheme from "@mock/hooks/useTheme";
import {widthLimit} from "@ui/components/Decorators/widthLimited";
import DetailProductsList from "@themes/default/components/DetailProductsList";
import {ProductDetailModal} from "@themes/default/components/ProductDetail";
import StoreSelectionBar from "@themes/default/components/StoreSelectionBar";
import {PercentageIcon} from "@ui/components/IconsSVGs";
import {noResultsPlaceholderStyles} from "@themes/default/components/DealsList/styles";
import useDealsPage from "@mock/hooks/useDealsPage";
import BackButton from "@themes/default/components/BackButton";

export default function DealsPage(props) {
  const {Header, DealsList, ProductDetail} = useThemeComponents();
  const theme = useTheme();

  const dealsPageProps = useDealsPage();

  const hasProducts = !dealsPageProps.products.noResults;
  return (
    <div>
      <Header />
      <Container theme={theme}>
        <StoreSelectionBar hideIfSingleStore={true} />
        <BackButton onClick={dealsPageProps.onBack} />
        <Section className={"deals-page__deals-list"}>
          <DealsList
            {...dealsPageProps}
            noResultsPlaceholderProps={getEmptyPlaceholderProps({
              title: "No deals available at the moment.",
              theme,
              site: dealsPageProps.site,
            })}
          />
        </Section>
        {hasProducts && (
          <Section className={"deals-page__products-list"}>
            <DetailProductsList
              site={dealsPageProps.site}
              {...dealsPageProps.products}
              noResultsPlaceholderProps={getEmptyPlaceholderProps({
                title: "No products On Sale at the moment.",
                theme,
                site: dealsPageProps.site,
              })}
            />
          </Section>
        )}
      </Container>
      <ProductDetailModal ProductDetailComponent={ProductDetail} {...props} />
    </div>
  );
}

const getEmptyPlaceholderProps = ({title, theme, site}) => {
  const placeholderStyles = noResultsPlaceholderStyles(theme, site);
  return {
    styles: {
      ...placeholderStyles,
      logo: {
        ...placeholderStyles.logo,
        backgroundColor: "none",
      },
    },
    TitleComponent: <span>{title}</span>,
    LogoIconComponent: PercentageIcon,
    TextComponent: (
      <>
        <span>It seems like we did not find what you are looking for.</span>
        <span>Please check later.</span>
      </>
    ),
  };
};

const Container = styled.div`
  margin: ${({theme}) => theme.v2.spacing(4)} auto 0 auto;
  max-width: ${widthLimit};
`;
const Section = styled.section`
  margin-bottom: ${({theme}) => theme.v2.spacing(16)};
`;
