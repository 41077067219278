import {makeThemeProps} from "@themes/default/props/utils";

export const dealsListStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getDealsListProps(theme, site);
};

export const noResultsPlaceholderStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getNoResultsPlaceholderProps(theme);
};
