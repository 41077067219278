import {applyMiddleware, createStore} from "redux";
import reducer from "./reducer";
import createSagaMiddleware from "redux-saga";
import eCommerceMiddleware from "../eCommerceMiddleware";
import {createInitializationQueueMiddleware} from "src/core/common/initializationQueueMiddleware";
import ActionTypes from "src/core/checkout/state/actionTypes";
import once from "lodash/once";

const sagaMiddleware = createSagaMiddleware();
const initializationQueueMiddleware = createInitializationQueueMiddleware(
  ActionTypes.REQUEST_LOAD_CART
);

const store = createStore(
  reducer,
  applyMiddleware(sagaMiddleware, eCommerceMiddleware, initializationQueueMiddleware)
);

export const initStore = once(async () => {
  return import("./sagas").then(Sagas => {
    sagaMiddleware.run(Sagas.createCartSaga);
    sagaMiddleware.run(Sagas.addItemSaga);
    sagaMiddleware.run(Sagas.deleteItemSaga);
    sagaMiddleware.run(Sagas.updateItemSaga);
    sagaMiddleware.run(Sagas.paymentSaga);
    sagaMiddleware.run(Sagas.loadCartSaga);
    sagaMiddleware.run(Sagas.loadOrderSaga);
    sagaMiddleware.run(Sagas.createOrderSaga);
    sagaMiddleware.run(Sagas.cartValidationSaga);
    sagaMiddleware.run(Sagas.loadOrdersListSaga);
    sagaMiddleware.run(Sagas.createOrderReviewSaga);
    sagaMiddleware.run(Sagas.setPaymentOptionSaga);
    sagaMiddleware.run(Sagas.setRewardSaga);
    sagaMiddleware.run(Sagas.setScheduleSaga);
    sagaMiddleware.run(Sagas.clearCartSaga);
    sagaMiddleware.run(Sagas.setPromoCodesSaga);
  });
});

export default store;
