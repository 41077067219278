import React from "react";
import BasePage from "./BasePage";
import CategorizedProductsBrowser from "@menu/components/CategorizedProductsBrowser";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";

const AdBanner = dynamic(() =>
  import("@themes/default/components/AdBanner").catch(logModuleLoadError("adBanner"))
);

export default function FlightHomePage(props) {
  const {showAdBanner} = useAdBanner();

  return (
    <BasePage disableMaxWidth={true} {...props}>
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <CategorizedProductsBrowser />
    </BasePage>
  );
}
