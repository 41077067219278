import React, {useEffect, useMemo, useState} from "react";
import useAppliedFilters from "src/core/common/hooks/useAppliedFilters";
import {PageHead} from "../../core/seo/components/Head";
import useSite from "../../core/sites/hooks/useSite";
import {useRefreshHook} from "src/core/common/hooks/useRefresh";
import useRouter from "src/core/common/hooks/useRouter";
import styled from "styled-components";
import media, {contentMaxWidth} from "src/themes/utils/media";
import {trackCategoryView} from "src/core/analytics/ecommerce/events";
import useFiltersCatalog from "@menu/hooks/useFiltersCatalog";
import useSubcategories from "src/core/common/hooks/useSubcategories";

function ProductsBrowser({render, disableMaxWidth = false}) {
  const [catalog] = useFiltersCatalog();

  const [refreshState] = useRefreshHook();
  const router = useRouter();
  const {productId} = router.query;
  const [paramFilters] = useAppliedFilters();
  const [filters, setFilters] = useState(paramFilters);

  useEffect(() => {
    if (!productId && !paramFilters.equals(filters)) {
      setFilters(paramFilters);
    }
  }, [productId, setFilters, paramFilters, filters]);

  const categories = catalog ? catalog.categories : [];

  const category = useMemo(() => {
    return categories.find(c => c.getSlug() === filters.category);
    // eslint-disable-next-line
  }, [catalog, filters.category]);

  const site = useSite();

  const {subcategories} = useSubcategories(category, categories);

  const categoryTitleTemplate = site.getOptions().getCategoryTitleTemplate();

  useEffect(() => {
    category && trackCategoryView(category);
  }, [category]);

  return (
    <React.Fragment>
      {category ? (
        <PageHead
          title={category.getName()}
          description={category.getDescription()}
          titleTemplate={categoryTitleTemplate}
        />
      ) : (
        <PageHead />
      )}
      <Container disableMaxWidth={disableMaxWidth} key={refreshState}>
        {render({
          category,
          subcategories,
          filters,
        })}
      </Container>
    </React.Fragment>
  );
}

const Container = styled.div`
  padding-top: 20px;

  > section > header,
  > section > p {
    padding: 0 ${({theme}) => theme.v2.spacing(8)};

    ${media.down("md")} {
      padding: 0 ${({theme}) => theme.v2.spacing(4)};
    }
  }

  width: 100%;
  max-width: ${({disableMaxWidth}) => (disableMaxWidth ? "none" : {contentMaxWidth})};
  box-sizing: border-box;
`;

export default ProductsBrowser;
