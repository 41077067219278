import React from "react";
import {useTheme} from "styled-components";
import {
  CartIconActions,
  HorizontalProductCard,
  ProductCard as ProductCardUI,
  renderNodeOrComponent,
  ResponsiveProductCard,
  SaleIndicator,
} from "@ui";
import useProductCard from "@mock/hooks/useProductCard";
import styles from "./styles";
import makeFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/makeFlowerTypeIndicator";
import productCardStyles from "@themes/default/components/ProductCard/styles";

export default function ProductCard(props) {
  const {site, actionProps, cartProps, flowerTypeIndicatorProps, ...productCardProps} =
    useProductCard(props);

  const {product, ...otherProps} = props;
  const theme = useTheme();

  const iconType = site.getUiConfiguration().flowerTypeIndicator.iconType;
  const FlowerTypeIndicator = makeFlowerTypeIndicator(iconType);
  const buyText =
    props.mode === "small" ? "Buy" : site.getUiConfiguration().productCard.buyText;

  const viewAllText = site.getUiConfiguration().showcasedProductsList.viewAll.text;

  const ActionsComponent = props.ActionsComponent ? (
    renderNodeOrComponent(props.ActionsComponent, {buyText, ...props, ...actionProps})
  ) : (
    <CartIconActions {...props} {...actionProps} />
  );

  const DesktopProductCard = (
    <div>
      <ProductCardUI
        {...otherProps}
        {...productCardProps}
        styles={productCardStyles(theme, {mode: props.mode}, site)}
        ActionsComponent={ActionsComponent}
        SaleIndicatorComponent={<SaleIndicator position={"left"} />}
        potencyTagsPosition="bottom"
        flowerTypeIndicatorProps={flowerTypeIndicatorProps}
        FlowerTypeIndicatorComponent={FlowerTypeIndicator}
        viewAllText={viewAllText}
      />
    </div>
  );

  const MobileActionsComponent = props.MobileActionsComponent ? (
    renderNodeOrComponent(props.MobileActionsComponent, {
      buyText,
      ...props,
      ...actionProps,
    })
  ) : (
    <CartIconActions {...props} {...actionProps} />
  );

  const MobileProductCard = (
    <div>
      <HorizontalProductCard
        {...props}
        {...productCardProps}
        styles={styles(theme, site, {isHorizontalInMobile: true})}
        showSizes
        potencyTagsPosition="bottom"
        FlowerTypeIndicatorComponent={FlowerTypeIndicator}
        flowerTypeIndicatorProps={flowerTypeIndicatorProps}
        ActionsComponent={MobileActionsComponent}
        SaleIndicatorComponent={<SaleIndicator position={"right"} />}
      />
    </div>
  );

  return (
    <ResponsiveProductCard
      DesktopProductCard={DesktopProductCard}
      MobileProductCard={props.mode === "small" ? DesktopProductCard : MobileProductCard}
    />
  );
}
