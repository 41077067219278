import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import Link from "@mock/components/modules/Link.js";
import selectionBarStyles from "./styles.js";
import useTheme from "@mock/hooks/useTheme.js";
import SelectionBar from "@ui/components/SelectionBar/SelectionBar";
import SelectionBarButton from "@ui/components/SelectionBar/SelectionBarButton";
import useStoreSelectionBar from "@mock/hooks/useStoreSelectionBar";

const StoreSelectionBar = props => {
  const {site, stores, getSelectionBarButtonProps, visible} = useStoreSelectionBar();
  const theme = useTheme();

  if (!visible) return null;

  return (
    <Container>
      <SelectionBar
        entries={stores}
        styles={selectionBarStyles(theme, site)}
        getSelectionBarButtonProps={getSelectionBarButtonProps}
        SelectionBarButtonComponent={<SelectionBarButton LinkComponent={Link} />}
      />
    </Container>
  );
};

const Container = styled.div`
  ${media.down("sm")} {
    padding: 0;

    ul {
      padding: 0 16px;
    }
  }
`;

export default StoreSelectionBar;
