import React from "react";
import AppliedFilters from "@themes/default/components/AppliedFilters";
import ShowcaseProductsList from "@themes/default/components/ShowcaseProductsList";
import SortAndFilterHeader from "@themes/default/components/SortAndFilterHeader";
import AppliedSearchFilter from "@themes/default/components/AppliedSearchFilter";
import useAppliedFilters from "@mock/hooks/useAppliedFilters";
import SeoHead from "@mock/components/modules/SeoHead";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";

export default function HomePage({children, ...props}) {
  const [filters] = useAppliedFilters();
  const {BasePage, ShowcaseDealsList, ShowcaseRecommendedProductsList} =
    useThemeComponents();
  return (
    <BasePage {...props} disableMaxWidth={true}>
      <SeoHead />
      <AppliedFilters />
      {filters?.search ? <AppliedSearchFilter /> : <SortAndFilterHeader />}
      <ShowcaseDealsList />
      <ShowcaseRecommendedProductsList filters={filters} />
      <ShowcaseProductsList />
    </BasePage>
  );
}
