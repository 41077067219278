import BasePage from "@themes/default/pages/BasePage";
import HomePage from "@themes/default/pages/HomePage";
import BrandDetailPage from "@themes/default/pages/BrandDetailPage";
import CategoryDetailPage from "@themes/default/pages/CategoryDetailPage";
import SearchPage from "@themes/default/pages/SearchPage";
import DealsPage from "@themes/default/pages/DealsPage";
import Button from "@themes/default/components/Button";
import Header from "@themes/default/components/Header";
import AnnouncementMessage from "@themes/default/components/AnnouncementMessage";
import ProductDetail from "@themes/default/components/ProductDetail";
import CategoryBar from "@themes/default/components/CategoryBar";
import DeliveryBanner from "@themes/default/components/DeliveryBanner";
import RecommendedProductsList from "@themes/default/components/RecommendedProductsList";
import ShowcaseProductsList from "@themes/default/components/ShowcaseProductsList";
import DealCard from "@themes/default/components/DealCard";
import ShowcaseDealsList from "@themes/default/components/ShowcaseDealsList";
import DealDetail from "@themes/default/components/DealDetail";
import ProductCard from "@themes/default/components/ProductCard";
import {DetailProductsListWrapper} from "@themes/default/components/DetailProductsList";
import PromotionalBanner from "@themes/default/components/PromotionalBanner";
import Title from "@themes/default/components/Title";
import ViewAllLink from "@themes/default/components/ViewAllLink";
import dynamic from "next/dynamic";
import DefaultDealsList from "@themes/default/components/DealsList";
import DealDetailPage from "@themes/default/pages/DealDetailPage";
import {getFlowerTypeIndicatorProps} from "@themes/default/themeStyles/flowerType";
import ShowcaseRecommendedProductsList from "packages/defaultTheme/src/components/ShowcaseRecommendedProductsList";

const AdBanner = dynamic(() => import("@themes/default/components/AdBanner"), {
  ssr: false,
});
const Footer = dynamic(() => import("@themes/default/components/Footer"), {ssr: false});

const config = {
  inherits: "legacy",
  components: {
    Button,
    Header,
    Footer,
    RecommendedProductsList,
    ShowCasedProductsList: ShowcaseProductsList,
    ShowcaseRecommendedProductsList,
    ProductsList: DetailProductsListWrapper,
    ProductDetail,
    DealCard,
    ShowcaseDealsList,
    DealsList: DefaultDealsList,
    DealDetail,
    AnnouncementMessage,
    CategoryBar,
    DeliveryBanner,
    ProductCard,
    ViewAllLink,
    Title,
    PromotionalBanner,
    AdBanner,
  },
  pages: {
    BasePage,
    BrandPage: BrandDetailPage,
    ProductsPage: CategoryDetailPage,
    SubcategoryPage: CategoryDetailPage,
    ProductDetailPage: CategoryDetailPage,
    DealDetailPage: DealDetailPage,
    HomePage: HomePage,
    AllCategoriesPage: HomePage,
    SearchPage,
    DealsPage,
  },
  options: {
    pages: {
      productDetail: {
        isModal: true,
        quantityPicker: {
          displayPrice: true,
        },
        flowerTypeIndicator: {
          beforeTags: true,
        },
        separators: {
          afterTitle: true,
          afterPrices: true,
        },
        sizes: {
          position: "top",
        },
      },
    },
    flowerType: {
      getFlowerTypeIndicatorProps,
    },
  },
};

export const components = {
  ...config.components,
  ...config.pages,
};

export default config;
