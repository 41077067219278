import React from "react";
import styles, {dealTitleCardStyles} from "./styles";
import useShowcaseDeals from "@mock/hooks/useShowcaseDeals";
import useShowcaseProductsList from "@mock/hooks/useShowcaseProductsList";
import CarouselCardList from "@ui/components/CarouselCardList";
import styled, {useTheme} from "styled-components";
import Link from "src/core/common/components/modules/Link";
import TitleCard from "@ui/components/TitleCard";
import DealsListHeader from "@ui/components/DealsList/DealsListHeader";
import ViewAllLink from "@menu/components/ViewAllLink";

export default function BasethemeShowcaseDealsList() {
  const theme = useTheme();
  const {
    deals,
    loading,
    site,
    DealCardComponent,
    showDealsList,
    dealsListTitle,
    allDealsLink,
    dealsListDescription,
  } = useShowcaseDeals();

  const {showHeader, showCategoryCard, productsPerLine} =
    site.getUiConfiguration().showcasedProductsList;

  const {onLastSlide, LoadingComponent} = useShowcaseProductsList({
    url: {pathname: allDealsLink?.pathname, params: {shop: allDealsLink?.params}},
  });

  if (!showDealsList) return null;

  const headerProps = {
    category: dealsListTitle,
    url: allDealsLink,
  };

  let dealCardProps = {
    styles: dealTitleCardStyles(theme, site),
    category: dealsListTitle,
    LinkComponent: Link,
    url: allDealsLink,
  };

  return (
    <Container>
      <CarouselCardList
        styles={styles(theme, site)}
        maxEntriesPerSlide={productsPerLine}
        skeleton={loading}
        entries={deals}
        onLastSlide={onLastSlide}
        LoadingComponent={LoadingComponent}
        showCategoryHeader={false}
        showCategoryCard={showCategoryCard}
        categoryCardProps={dealCardProps}
        HeaderComponent={
          <DealsListHeader
            title={dealsListTitle}
            description={dealsListDescription}
            ViewAllComponent={<ViewAllLink to={allDealsLink} />}
          />
        }
        renderCard={({element, skeleton, isFirst, dim}) => {
          if (element?.isCategoryCard && dim !== "sm") {
            return (
              <TitleCard
                styles={dealTitleCardStyles(theme, site)}
                title={dealsListTitle}
                LinkComponent={Link}
                url={{
                  pathname: allDealsLink?.pathname,
                  params: {shop: allDealsLink?.params},
                }}
              />
            );
          }
          return (
            <DealCardComponent
              skeleton={skeleton}
              {...element}
              deal={element}
              isFirst={isFirst}
              showHeader={showHeader}
              headerProps={headerProps}
            />
          );
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
`;
