/* eslint-disable no-restricted-globals */
import TymberAPIConfig from "./config";
import {makeJsonAPIPayload, v1} from "./utils";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, isSupported, onMessage} from "firebase/messaging";
import {site} from "./shops";

const LOCAL_STORAGE_DEVICE_TOKEN_KEY = "device-web-notifications-token";

export const setupFcmMessaging = () => {
  if (TymberAPIConfig.fcmActive === "false") {
    console.debug("FCM disabled in env vars!!");
    return;
  }

  const firebaseConfig = {
    apiKey: TymberAPIConfig.fcmApiKey,
    messagingSenderId: TymberAPIConfig.fcmMessagingSenderId,
    appId: TymberAPIConfig.fcmAppId,
    projectId: TymberAPIConfig.fcmProjectId,
  };

  initializeApp(firebaseConfig);

  isSupported()
    .then(supported => {
      if (supported) {
        registerServiceWorker(firebaseConfig);
        addForegroundMessageHandler();
      }
    })
    .catch(error =>
      console.error("Error verifying if browser is supported.", "error", error)
    );
};

const registerServiceWorker = firebaseConfig => {
  if (Notification.permission === "denied") {
    console.debug(
      "Web notifications permission denied by user or browser",
      "warning",
      null
    );
  } else if ("serviceWorker" in navigator) {
    const messaging = getMessaging();
    const axios = TymberAPIConfig.axios();
    site(axios).then(response =>
      navigator.serviceWorker
        .register(
          `/firebase-messaging-sw.js?firebaseConfig=${JSON.stringify(
            firebaseConfig
          )}&defaultUrlClick=${response.data.attributes.url}`
        )
        .then(function (registration) {
          getToken(messaging, {
            vapidKey: TymberAPIConfig.fcmWebKey,
            serviceWorkerRegistration: registration,
          })
            .then(currentToken => {
              if (currentToken) {
                registerDevice(currentToken);
              } else {
                console.debug(
                  "No registration token available for web notifications. Request permission to generate one.",
                  "warning"
                );
              }
            })
            .catch(error => {
              console.debug("An error occurred while retrieving token. ", error);
              console.debug(
                "Error occurred while retrieving token for web notifications.",
                "error",
                error
              );
              unregisterServiceWorker();
            });
        })
        .catch(error => {
          console.debug("Service worker registration failed, error:", error);
          console.debug(
            "Error registering service worker for web notifications.",
            "error",
            error
          );
        })
    );
  }
};

const registerDevice = fcmToken => {
  const axios = TymberAPIConfig.axios();
  const jsonPayload = makeJsonAPIPayload("devices", {
    provider: "fcm",
    platform: "web",
    token: fcmToken,
  });

  axios
    .post(v1("users/me/devices/"), jsonPayload)
    .then(response =>
      localStorage.setItem(LOCAL_STORAGE_DEVICE_TOKEN_KEY, response.data.data.id)
    )
    .catch(error =>
      console.debug("Error registering device for web notifications.", "error", error)
    );
};

export const unregisterDevice = async () => {
  const axios = TymberAPIConfig.axios();
  const deviceToken = localStorage.getItem(LOCAL_STORAGE_DEVICE_TOKEN_KEY);

  if (deviceToken !== null) {
    return axios
      .delete(v1("users/me/devices/" + deviceToken))
      .then(() => {
        localStorage.removeItem(LOCAL_STORAGE_DEVICE_TOKEN_KEY);
        unregisterServiceWorker();
      })
      .catch(error =>
        console.debug("Error unregistering device for web notifications.", "error", error)
      );
  }
};

const unregisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .getRegistrations()
      .then(function (registrations) {
        for (let registration of registrations) {
          if (registration.active.scriptURL.includes("firebase-messaging-sw")) {
            registration.unregister();
            return;
          }
        }
      })
      .catch(error =>
        console.debug(
          "Error unregistering service worker for web notifications.",
          "error",
          error
        )
      );
  }
};

const addForegroundMessageHandler = () => {
  const messaging = getMessaging();
  onMessage(messaging, payload => {
    console.debug("[firebase-messaging-sw.js] Received foreground message ", payload);

    if (payload.notification) {
      navigator.serviceWorker.ready.then(registration =>
        registration.showNotification(payload.notification.title, {
          body: payload.notification.body,
          icon: payload.notification.icon,
          data: payload,
        })
      );
    }
  });
};
