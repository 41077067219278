import React from "react";
import styled from "styled-components";
import Header from "src/core/common/components/collections/menu/simple";
import OptionsList from "src/core/common/components/collections/menu/menu-elements/OptionsList";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import useBasePage from "src/core/common/hooks/useBasePage";
import AnnouncementMessage from "@menu/components/AnnouncementMessage";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";

const AdBanner = dynamic(() =>
  import("@themes/default/components/AdBanner").catch(logModuleLoadError("adBanner"))
);

const ProductDetailModal = dynamic(
  () =>
    import("src/core/pages/ProductDetail").catch(
      logModuleLoadError("ProductDetail", {critical: true})
    ),
  {
    ssr: true,
  }
);

export default function MedLeafBasePage({children, ...props}) {
  const {showAnnouncementMessage} = useBasePage();
  const {showAdBanner} = useAdBanner();

  return (
    <div>
      <Header isSearchEnabled={true} options={<OptionsList />} />
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      {showAnnouncementMessage && <AnnouncementMessage />}
      <Content disableMaxWidth={props.disableMaxWidth}>{children}</Content>
      <ProductDetailModal {...props} />
    </div>
  );
}

const Content = styled.div`
  margin: 0 auto;
  max-width: ${({disableMaxWidth}) => (disableMaxWidth ? "none" : "1440px")};
`;
